div#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.App {
  text-align: center;

  a {
    text-decoration: none;
  }

  .MuiCardActions-root {
    justify-content: center;
  }
}
